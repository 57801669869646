@import '~@travel-ui/styles/src/utility';

$couponWidth: 240px;

.bottomWrapper {
  padding: $spacing-16;
  border-top: 1px solid $pastel-gray;
  display: flex;
  justify-content: space-between;

  @include media(sp, tl) {
    width: 100%;
    flex-direction: column;
    border: 0;
    padding: $spacing-08;
  }
}

.couponWrapper {
  display: flex;
  overflow: auto;
  white-space: nowrap;

  @include media(pc) {
    max-width: 2 * ($couponWidth + $spacing-08);
  }
}

.couponIncentive {
  display: inline-block;
  margin-right: $spacing-04;
}

.coupon {
  @include resetButtonStyle;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  font-size: $font-mars;
  height: 76px;
  width: $couponWidth;
  min-width: $couponWidth;
  border: 1px solid $sonic-silver;
  border-radius: 10px;
  padding: $spacing-08;
  margin-right: $spacing-08;
  cursor: pointer;

  ::before {
    content: '';
    position: absolute;
    top: -1px;
    right: 20%;
    height: 6px;
    width: 16px;
    background: $white;
    border: 1px solid $sonic-silver;
    border-top: 1px solid white;
    border-radius: 0 0 12px 12px;
  }

  ::after {
    content: '';
    position: absolute;
    bottom: -1px;
    right: 20%;
    height: 6px;
    width: 16px;
    background: $white;
    border: 1px solid $sonic-silver;
    border-bottom: 1px solid white;
    border-radius: 12px 12px 0 0;
  }
}

.couponTitle {
  color: $dark-cyan;
  width: 100%;
  @include font-sp-body-b2-bold;
  @include text-truncate;

  @include media(pc) {
    @include font-pc-body-b2-bold;
  }
}

.applied {
  display: flex;
  align-items: center;
  gap: $spacing-02;
  border: 1px solid $dark-cyan;
  width: fit-content;
  padding: $spacing-02 $spacing-04;
  border-radius: $border-radius;
  color: $dark-cyan;
  margin-top: $spacing-04;
}

.isApplied {
  background-color: $alice-blue;
}

.icon {
  position: absolute;
  right: 4px;
  top: calc(50% - 8px);
}

.container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @include media(sp, tl) {
    flex-direction: column;
  }
}

.priceWrapper {
  margin-right: $spacing-16;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include media(sp, tl) {
    justify-content: space-between;
    width: 100%;
    margin-right: 0;
    margin-bottom: $spacing-08;
    margin-top: $spacing-08;
  }
}

.content {
  @include media(sp, tl) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.discountWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: $font-mars;

  .tag {
    border-radius: 200px;
    text-decoration: unset;
    margin: $spacing-04 0;
  }

  .basePrice {
    color: $scarlet;
    margin-right: $spacing-04;
    text-decoration: line-through;
  }
}

.totalPrice {
  font-size: $font-saturn;
  font-weight: bold;
}

.priceFor {
  display: flex;
  font-size: $font-venus;
  color: $sonic-silver;
}

.button {
  height: 40px;
  max-height: 40px;
  min-width: 163px;
  max-width: 100%;
  @include media(sp, tl) {
    width: 100%;
  }
}

.unSelectedDate {
  justify-content: flex-end;
}

.errorMessageWrapper {
  &#{&} {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: $spacing-24;

    @include media(sp) {
      padding-right: 0;
      background: $white;
      align-items: start;
    }
  }
}

.errorIcon {
  &#{&} {
    margin-top: 0;
    @include media(sp) {
      margin-top: $spacing-02;
    }
  }
}

.currencyInfo {
  @include font-pc-captions-c1;
  margin-bottom: $spacing-16;
  color: $sonic-silver;
}

.couponItemDialog {
  @include media(pc) {
    > div:first-child {
      max-height: 85vh;
    }
  }
}

.couponDetail {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: $spacing-16;

  .contentWrapper {
    text-align: left;
  }

  @include media(pc) {
    padding: $spacing-24;
    padding-top: 0;
  }

  @include media(tl) {
    padding: $spacing-24;
  }

  .annotation {
    color: $sonic-silver;
  }

  .couponDetailTitle {
    padding-bottom: $spacing-08;
  }
}

.couponItems {
  @include media(pc, tl) {
    display: flex;

    > :nth-child(2) {
      margin-left: $spacing-16;
    }
  }
}
