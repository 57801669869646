@import '~@travel-ui/styles/src/utility';

.popup {
  right: -24px;
  padding: 0;
}

.vacancyCalendarHighlight {
  &:not([class*='invalidMonth']):not([class*='outDated']) {
    cursor: pointer;
    &[class*='selectedStartDate'],
    &[class*='selectedEndDate'],
    &[class*='selected'] {
      background: $cilantro;
      color: $white;
    }

    &[class*='hoveredStartDate'],
    &[class*='hoveredEndDate'],
    &[class*='inSelectedRange'] {
      background: $laurel-green;
    }

    &[class*='selectedStartDate'] {
      @include border-radius-left($border-radius-4px);
    }

    &[class*='selectedEndDate'] {
      @include border-radius-right($border-radius-4px);
    }
  }

  @include media(pc) {
    &[class*='unSelected']:hover {
      background: $laurel-green;
    }
  }
}

.vacancyCalendarHighlightError {
  &:not([class*='invalidMonth']):not([class*='outDated']) {
    cursor: pointer;
    &[class*='selectedStartDate'],
    &[class*='selectedEndDate'],
    &[class*='selected'] {
      background: $sonic-silver;
      color: $white;
    }

    &[class*='hoveredStartDate'],
    &[class*='hoveredEndDate'],
    &[class*='inSelectedRange'] {
      background: $isabelline;
    }

    &[class*='selectedStartDate'] {
      @include border-radius-left($border-radius-4px);
    }

    &[class*='selectedEndDate'] {
      @include border-radius-right($border-radius-4px);
    }
  }
}

.slightSpaceAndRounding {
  min-width: $spacing-48;
}

.dialogContent {
  flex-basis: 30%;
  padding: $spacing-16;
  border-left: 1px solid $pastel-gray;
  position: relative;
  overflow: hidden;

  @include media(pc, tl) {
    max-width: 254px;
    padding: 0;
    padding-top: $spacing-48;
  }
}

.calendarWrapper {
  @include media(pc, tl) {
    padding: $spacing-16 $spacing-04;
  }
}

.dayItem {
  &#{&} {
    height: auto;
    width: 52px;
    @include media(pc, tl) {
      width: 60px;
    }
  }
}

.dialog {
  @include media(tl) {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .title {
      flex: 0 1 auto;
    }

    .content {
      flex: 1 1 auto;
    }
  }

  @include media(tl) {
    top: 0;
  }
}
