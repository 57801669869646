@import '~@travel-ui/styles/src/utility';

.wrapper {
  // same as couponItem css
  margin-top: $spacing-m;
  border-radius: 10px;
  border: 1px solid $isabelline;
  width: 100%;
  padding: $spacing-m;
  overflow: hidden;

  @include media(tl, pc) {
    // 318 + $spacing-m * 2
    width: 350px;
  }
}

.couponCover {
  // nothing at the moment
}

.button {
  margin-top: $spacing-m;
}

.conditions {
  padding-top: $spacing-m;

  :nth-child(n) {
    height: 15px;
  }
}

.accordionWrapper {
  padding-top: $spacing-m;
  text-align: center;

  .accordion {
    width: 130px;
    margin: 0 auto;
  }
}
