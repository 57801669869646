@import '~@travel-ui/styles/src/utility';

.wrapper {
  border: 1px solid $isabelline;
  @include border-radius-all($border-radius-10px);
  padding: $spacing-16;
  margin-top: $spacing-16;

  @include media(tl, pc) {
    max-width: 350px;
  }
}

.couponCover {
  :nth-child(2) {
    text-align: left;
  }
}

.detailWrapper {
  .couponDetail {
    padding: $spacing-08 0;
  }

  .couponText {
    @include media(pc) {
      > :first-child {
        padding-right: $spacing-08;
      }
    }
  }

  .buttonWrapper {
    padding-top: $spacing-16;
    padding-bottom: $spacing-08;

    .appliedMessage {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $alice-blue;
      color: $dark-cyan;
      border-radius: 4px;
      font-weight: bold;
      min-height: 36px;
      padding: $spacing-04 $spacing-08;

      .checkWrapper {
        height: 14px;
        width: 14px;
        background-color: $dark-cyan;
        border-radius: 50%;
        margin-right: $spacing-08;
        text-align: center;
        vertical-align: middle;

        > svg {
          position: relative;
          // adjust half svg size(5) with height/width(14)
          top: 18%;
          left: 18%;
        }
      }
    }
  }
}

.accordionButton {
  @include resetButtonStyle;

  width: 100%;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $spacing-08;
  font-weight: bold;
}

.buttonText {
  color: $cilantro;
  font-size: $font-venus;
  line-height: $line-height-sub-label;
  cursor: pointer;
  padding-right: $spacing-08;

  &:hover {
    color: $lincoln-green;
  }

  @include media(tl, pc) {
    margin-top: auto;
    margin-bottom: $spacing-08;
  }
}

.couponContent {
  border-right: 1px dashed $sonic-silver;
  border-bottom: 1px dashed $sonic-silver;
  border-left: 1px dashed $sonic-silver;
  padding: $spacing-16;

  @include media(tl, pc) {
    display: flex;
    flex-direction: column;
    height: 30%;
  }
}

.button {
  &#{&} {
    min-height: 36px;
    width: 100%;
    max-width: unset;
    margin: auto;
    padding: $spacing-04 $spacing-08;
  }
}

.alert {
  margin-top: $spacing-16;
  margin-bottom: 0;
}

.alertDescription {
  color: $cilantro;
  font-size: $font-venus;
}
