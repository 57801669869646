@import '~@travel-ui/styles/src/utility';

// provider list
.providerListButtonGroup {
  border: 1px solid $cloudy-gray;
  align-items: initial;
  border-radius: $spacing-s;
  overflow: hidden;
  height: 44px;

  &:hover {
    border-color: $lincoln-green;
  }
}

.providerListButton {
  font-weight: normal;
  min-width: 0;

  svg path:not(:first-child) {
    fill: $jet;
  }

  @include media(sp, tl) {
    border: 1px solid $cloudy-gray;
    text-align: left;
    color: $jet;
  }

  @include media(tl, pc) {
    &#{&} {
      color: $jet;
      width: 100%;
    }
  }
}

// provider info
.providerInfoDateButton {
  @include media(pc, tl) {
    height: 40px;
  }
}

.picker--providerInfo {
  @include media(pc) {
    padding: 0 5%;
  }
}

// top page
.topPageButtonGroup {
  border-radius: $border-radius;
  height: 44px;
  overflow: hidden;

  &.withBorder {
    border: 1px solid $cloudy-gray;

    &:hover {
      border-color: $lincoln-green;
    }
  }
}

.topAreaButtonGroup {
  border: 1px solid $cloudy-gray;
  border-radius: $border-radius-8px;
  height: 48px;
  overflow: hidden;
  &:hover {
    border-color: $lincoln-green;
  }

  @include media(pc) {
    width: 304px;
  }
}

.topAreaSmallButton {
  &#{&} {
    border: 1px solid $cloudy-gray;
    border-radius: $border-radius-6px;
    height: 48px;
  }
}

.topPageButton {
  color: $jet;
  padding: 10px;
}

// rate plan
.picker--rateplan {
  right: 0;
}

// modification
.modificationButtonGroup {
  border-radius: $border-radius;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  > div {
    display: none;
  }
  button {
    width: 100%;
    border: 1px solid $pastel-gray;
    border-radius: $border-radius-4px;
    margin-bottom: $spacing-m;
  }
}

.modificationButton {
  padding: 10px;
}

// coupon search
.couponSearchSimpleButton {
  width: 100%;
  min-height: 44px;
  text-align: left;
  color: $jet;
  font-weight: normal;
  border: none;

  .couponButtonIcon {
    margin-right: $spacing-s;
  }
}

.couponSearchButtonIcon {
  svg path:not(:first-child) {
    fill: $jet;
  }
}

// override className
.placeholderColor {
  &#{&} {
    color: $sonic-silver;
  }
}

.vcWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.availableButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $spacing-08;
  font-weight: bold;
  color: $cilantro;
}
