@import '~@travel-ui/styles/src/utility';

button.button {
  @include resetButtonStyle;
  padding: 0 $spacing-base;
  white-space: nowrap;
  cursor: pointer;
  color: $jet;
  font-weight: normal;
  width: 100%;
  text-align: left;
  background: $white;
  padding: 10px;

  &:hover {
    background-color: $white;
  }
}

button.placeholder {
  color: $sonic-silver;
}
