@import '~@travel-ui/styles/src/utility';

.row {
  display: flex;
  font-size: $font-mars;
  line-height: $line-height-sub-body;
  gap: $spacing-16;

  &:not(:last-child) {
    margin-bottom: $spacing-08;

    @include media(tl, pc) {
      margin-bottom: $spacing-04;
    }
  }

  > span {
    @include break-word;
    width: 50%;
  }

  @include media(tl, pc) {
    font-size: $font-venus;
    line-height: $line-height-body;

    > span:nth-child(odd) {
      font-weight: bold;
      flex: 1 1 40%;
    }
  }

  > span:nth-child(even) {
    @include media(pc) {
      flex: 1 1 60%;
    }
  }
}
