@import '~@travel-ui/styles/src/utility';

.seperateLine {
  align-self: center;
  background: $isabelline;
  height: 70%;
  width: 1px;
}

.dateButtonGroupWrapper {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 58px;
  background-color: $white;
}
