@import '~@travel-ui/styles/src/utility';

.wrapper {
  .incentive {
    // for normal incentive
    div:first-child {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: $font-saturn;
      line-height: $line-height-sub-title;
      color: $scarlet;

      > svg {
        fill: $scarlet;

        // to overwrite the deep fill #fff
        :nth-child(n) {
          fill: $scarlet;
        }
      }

      > span:first-of-type {
        padding-left: $spacing-08;
      }
    }

    .localAmount {
      font-weight: normal;
      font-size: $font-venus;
      line-height: $line-height-sub-title;
      color: $sonic-silver;
      padding: $spacing-04 0;
    }

    @include media(sp) {
      margin-bottom: $spacing-04;
    }
  }

  .title {
    font-weight: bold;
  }

  .promotionPeriod {
    font-size: $font-mars;
  }
}
