@import '~@travel-ui/styles/src/utility';

.wrapper {
  background: $white;
  display: flex;
  overflow: hidden;
  position: relative;
}

.dialog {
  &#{&} {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zIndex-modal;

    @include media(sp, tl) {
      @include full-vh-box;
      width: 100%;
    }
  }
}

.dialogAction {
  position: relative;

  @include media(tl) {
    & > button {
      max-width: 234px;
    }
  }
}

.dialogContentBox {
  @include box-shadow-middle;
  background: $white;
  border: 1px solid $pastel-gray;
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  > .infoBox {
    border-top: 1px solid $isabelline;
    margin: 0 $spacing-m;
    padding: $spacing-m 0 $spacing-l 0;
    display: flex;
    position: relative;
    justify-content: center;
    user-select: none;
  }

  &.typeDialog {
    box-shadow: none;
    border: none;
    border-radius: 0;
    width: 100%;
  }
}

.dialogDateButton {
  padding: 8px $spacing-m;
  > div {
    font-size: $font-neptune;
    font-weight: bold;
    color: $cilantro;
    min-height: 40px;
  }
}

.dialogHeader {
  &#{&} {
    border: 1px solid $isabelline;
    box-shadow: none;
  }

  @include media(tl) {
    & > h3 {
      text-align: center;
    }
  }
}

// DatePicker

.datePicker {
  align-items: flex-start;
  display: flex;
  min-height: 324px;
  position: relative;
  flex: 1 1 auto;

  &.typeDialog {
    flex-direction: column;

    @include media(tl) {
      padding: 0 120px;
    }
  }

  > .icon {
    cursor: pointer;
    position: absolute;
    top: $spacing-m;

    &.invalid {
      pointer-events: none;

      path[fill] {
        fill: $pastel-gray;
      }
    }

    &.right {
      right: 14px;
    }

    &.left {
      left: 14px;
    }
  }
}

.dateButtonGroup {
  height: 58px;

  @include media(tl) {
    padding: 0 120px;
  }
}

.clearButton {
  @include resetButtonStyle;

  right: 0;
  position: absolute;
  font-weight: bold;
  font-size: $font-venus;
  padding: $spacing-base $spacing-l;
  border-radius: 3px;
  color: $cilantro;

  &:disabled {
    color: $pastel-gray;
  }

  &InDialog {
    left: unset;
    right: 16px;
  }
}

.dialogTitle {
  display: flex;
  align-items: center;
}

// Calendar

.calendar {
  @include flex-center;
  flex-direction: column;
  padding: $spacing-m 14px;
  width: 100%;
}

.calendarLabel {
  margin-bottom: $spacing-l;
  user-select: none;

  @include media(sp) {
    margin-bottom: $spacing-base;
  }
}

.calendarWeekHeader {
  width: 100%;
  flex: 0 0 20px;
  margin-bottom: $spacing-m;
  box-sizing: border-box;

  @include media(sp) {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: $spacing-base;
    padding: $spacing-base 14px;
  }

  @include media(tl) {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    padding: $spacing-base 134px;
    margin-bottom: $spacing-l;
  }
}

.calendarWeekHeaderItem {
  flex: 1 1 40px;
  width: auto;
}

.calendarBody {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.loading {
  z-index: $zIndex-popup;
}

// custom classes when right box exists
.rightBox {
  display: flex;
  width: 1128px;

  .infoBox {
    display: flex;
    padding: $spacing-16;
    justify-content: center;
    align-items: center;
    .clearButton {
      left: 0;
      width: 0;
    }
  }

  .datePickerWrapper {
    padding: $spacing-08;
    flex: 1 1 auto;
  }
}
