@import '~@travel-ui/styles/src/utility';

.columnCentered {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  pointer-events: none;
}

.roomCountInfoSection {
  min-height: 32px;
}

.teenyText {
  @include font-pc-captions-c1;
  color: $sonic-silver;
  font-weight: bold;
  overflow: hidden;
  width: 52px;
  height: 16px;
  text-align: center;
  white-space: nowrap;
  @include media(pc, tl) {
    width: 60px;
  }
}

.selectedDayState {
  color: $white;
}

.bed {
  font-size: $font-mars;
  color: $sonic-silver;
  font-weight: normal;
  height: 16px;
  display: flex;
  align-items: center;
}
