@import '~@travel-ui/styles/src/utility';

.container {
  @include flex-center;

  height: 32px;
  align-items: center;

  .button {
    @include resetButtonStyle;

    width: 32px;
  }

  .enabledButton {
    cursor: default;
  }

  .enabledButton:hover {
    svg g,
    svg path {
      fill: $lincoln-green;
    }

    svg rect {
      stroke: $lincoln-green;
    }
  }

  .disabledButton {
    cursor: default;
    pointer-events: none;

    svg g,
    svg path {
      fill: $pastel-gray;
    }

    svg rect {
      stroke: $pastel-gray;
    }
  }

  .counter {
    @include font-body;

    width: 34px;
    text-align: center;
    color: $jet;

    &.bold {
      font-weight: bold;
    }
  }

  .disabledCounter {
    color: $pastel-gray;
  }
}
