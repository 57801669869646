@import '~@travel-ui/styles/src/utility';

.dateButton {
  @include font-body;
  background: $white;
  border: none;
  cursor: pointer;
  display: flex;
  flex-basis: calc(50% - 1px);
  flex-shrink: 1;
  flex-grow: 1;
  width: calc(50% - 1px);
  white-space: nowrap;

  &.invalid {
    color: $sonic-silver;
  }

  &.active {
    border-bottom: 2px solid $cilantro;
  }

  &.withIcon {
    align-items: center;
  }

  > .icon {
    margin-right: $spacing-base;
  }

  > .container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    min-width: 0;

    > .label {
      @include font-sub-body;
      font-weight: normal;
      color: $jet;
    }

    .text {
      @include text-truncate;
      width: 100%;
    }
  }
}
