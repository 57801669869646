@import '~@travel-ui/styles/src/utility';

.button {
  padding: 0 $spacing-base;
  white-space: nowrap;
  max-width: initial;
  width: 100%;

  @include media(pc, tl) {
    height: 44px;
  }

  > div {
    justify-content: flex-start;
    width: 100%;
  }
}

.label {
  @include text-truncate;
}
