@import '~@travel-ui/styles/src/utility';
@import '../../core/App/app.module.scss';

.priceFor {
  display: flex;
  font-size: $font-venus;
  color: $sonic-silver;
  
  > div:first-of-type {
    margin-left: 2px;
  }
}
