@import '~@travel-ui/styles/src/utility';

.container {
  display: inline-block;

  .selectBoxWrapper {
    display: flex;
    width: 100%;

    .unit {
      padding-left: $spacing-s;
      align-self: center;
    }
  }
}

.label {
  display: block;
  font-weight: bold;
  overflow-wrap: break-word;
  margin-bottom: $spacing-s;
}

.defaultIcon {
  position: absolute;
  // FOR IE - need to set top,right for alignment of the icon //
  top: 0px;
  right: 0px;
  padding: $spacing-base;
  z-index: 1;
}

.viewport {
  @include text-truncate;

  position: absolute;
  // IE - need to set top, right for alignment of the select
  top: 0px;
  left: 0px;
  background-image: none;
  border-radius: $border-radius;
  box-shadow: none;
  color: $jet;
  display: block;
  font-size: $font-venus;
  height: 100%;
  padding: 0 $spacing-l 0 $spacing-base;
  text-align: left;
  width: 100%;
  z-index: 0;

  &:not(.noBorder) {
    border: 1px solid $pastel-gray;
  }
}

.hiddenSelect {
  opacity: 0;
  position: relative;
  // IE - need to set top for alignment of the select option
  top: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-right: solid 35px transparent;
  border-left: solid $spacing-base transparent;

  // fix inherit text color issue on Windows OS
  color: black;
  option {
    color: black;
  }

  &:disabled ~ .viewport {
    background-color: $isabelline;
    color: $pastel-gray;
    cursor: default;
  }

  &:focus ~ .viewport,
  &:hover ~ .viewport {
    color: inherit;
  }

  &:not(.noBorder) {
    &:disabled ~ .viewport {
      border: 1px solid $pastel-gray;
    }

    &:focus ~ .viewport,
    &:hover ~ .viewport {
      border: 1px solid $cilantro;
    }
  }
}

.selectBox {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 44px;
  line-height: 44px;
  justify-content: flex-end;
  position: relative;
  width: 100%;

  option[default] {
    display: none;
  }

  .error {
    border: 1px solid $apple-red;
    color: $jet;
  }
}

.highlight {
  background-color: $mellow-yellow;
  // border: 1px solid $sunflower;
}
.selectedOption {
  display: flex;
  align-items: center;
  p {
    padding-left: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.hidden {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}
