@import '~@travel-ui/styles/src/utility';

.formButton {
  @include resetButtonStyle;
  background-color: $white;
  height: 44px;
  width: 100%;
  border-radius: $border-radius;
  color: $jet;
  padding: 10px;
  display: flex;
  align-items: center;

  .amountContainer {
    cursor: pointer;
    margin-left: 5px;
    font-size: $font-venus;
    vertical-align: middle;
    text-align: left;
    white-space: nowrap;
    display: flex;

    @include media(pc, tl) {
      flex: 1 1 auto;
    }
  }
}

.amountItem {
  margin-right: $spacing-m;

  &:last-child {
    margin-right: 0;
  }

  .amountContainerSmall {
    flex: 0;
  }
}

.formButtonSmall {
  justify-content: flex-start;
  margin: 0;
}

.simpleFormButton {
  width: 100%;
  white-space: nowrap;
  padding: 0 $spacing-base;

  > div {
    justify-content: flex-start;
  }

  @include media(pc, tl) {
    height: 44px;
    max-width: initial;
  }
}

.amountFormWrapper {
  &:not(:last-child) {
    margin-bottom: $spacing-l;
  }

  &.roomForm {
    margin-bottom: 0;
  }
}

.amountForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .amountLabel {
    line-height: 20px;
    height: 100%;
  }
}

.providerInfoLabel {
  @include font-sub-label;
  color: $cilantro;
  cursor: pointer;
}

.providerInfoPcLabel {
  @include resetButtonStyle;
  @include flex-center;
  cursor: pointer;
  white-space: nowrap;
  color: $cilantro;

  &:hover {
    color: $lincoln-green;
  }

  .guestIcon {
    margin-right: 14px;
  }

  .labelWrapper {
    text-align: left;
  }

  .guestLabel {
    font-size: $font-mars;
    line-height: $line-height-category;
    color: $jet;
  }

  .guestCount {
    font-size: $font-saturn;
    font-weight: bold;
    line-height: $line-height-sub-title;
  }
}
