@import '~@travel-ui/styles/src/utility';

.amountInputContainer {
  font-style: normal;
  font-weight: normal;
  color: $jet;
  line-height: $line-height-title;
  position: relative;

  @include media(pc) {
    margin: 0 auto;
  }

  .formButton {
    @include resetButtonStyle;
    background-color: $white;
    color: $jet;
    height: 44px;
    width: 100%;
    border-radius: $border-radius;
    padding: 10px;
    display: flex;
    align-items: center;

    .amountContainer {
      cursor: pointer;
      margin-left: $spacing-base;
      font-size: $font-venus;
      vertical-align: middle;
      text-align: left;
      white-space: nowrap;

      @include media(pc, tl) {
        flex: 1 1 auto;
      }
    }
  }

  .amountItem {
    margin-right: $spacing-m;

    &:last-child {
      margin-right: 0;
    }

    .amountContainerSmall {
      flex: 0;
    }
  }

  .formButtonSmall {
    justify-content: center;
    margin: 0;
  }

  .simpleFormButton {
    width: 100%;
    white-space: nowrap;
    padding: 0 $spacing-base;

    > div {
      justify-content: flex-start;
    }

    @include media(pc, tl) {
      height: 44px;
      max-width: initial;
    }
  }
}

.popup {
  // reset default padding
  padding: 0;
}

.popupContainer {
  width: 100%;

  &.providerInfoPosition {
    @include media(pc) {
      width: 410px;
      /** To get an accurate value for shifting from right side */
      right: 0;
    }
  }

  &.providerListPosition {
    @include media(pc) {
      margin-top: $spacing-s;
      width: 376px;
    }
  }
}

.contentWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media(pc) {
    // this is max-height of pc content, please update the value accordingly with any changes
    max-height: 450px;
    overflow: auto;
    padding: $spacing-xl $spacing-xl $spacing-m;
  }
}

.amountFormContainer {
  &:not(:first-child) {
    margin-top: $spacing-xl;
  }

  .amountFormWrapper {
    &:not(:last-child) {
      margin-bottom: $spacing-l;
    }
  }

  .amountName {
    margin-bottom: 12px;
    font-weight: bold;
  }

  .amountForm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .amountLabel {
      line-height: 20px;
      height: 100%;
    }
  }

  .infoIcon {
    margin-right: $spacing-base;
    > g > path:nth-child(2) {
      fill: $deep-blue;
    }
  }

  .infoWrapper {
    background-color: $info-blue;
    color: $deep-blue;
    padding: $spacing-base;
    border-radius: $spacing-s;
    margin-top: $spacing-m;
    overflow: hidden;
    white-space: initial;
  }

  .ageFormContainer {
    margin-top: $spacing-m;

    .label {
      display: block;
    }

    .ageSelectBox {
      margin-top: $spacing-base;
    }
  }
}

.amountOk {
  padding: $spacing-m $spacing-xl;
  margin-top: 0;
  background: $white;

  &.withBoxShadow {
    @include box-shadow-top;
  }

  .amountOkButton {
    width: 100%;
  }
}

.providerInfoLabel {
  @include font-sub-label;
  color: $cilantro;
  cursor: pointer;
}

.providerInfoPcLabel {
  @include resetButtonStyle;
  @include flex-center;
  cursor: pointer;
  white-space: nowrap;
  color: $cilantro;

  &:hover {
    color: $lincoln-green;
  }

  .guestIcon {
    margin-right: 14px;
  }

  .labelWrapper {
    text-align: left;
  }

  .guestLabel {
    font-size: $font-mars;
    line-height: $line-height-category;
    color: $jet;
  }

  .guestCount {
    font-size: $font-saturn;
    font-weight: bold;
    line-height: $line-height-sub-title;
  }
}

.dialogWrapper {
  @include media(tl) {
    @include box(100%);
  }
}

.dialogContent {
  @include media(sp) {
    padding: $spacing-m;
  }

  @include media(tl) {
    height: 100%;
    padding: 40px 120px;
  }
}

.dialogHeader {
  @include media(tl) {
    & > h3 {
      text-align: center;
    }
  }
}

.dialogAction {
  @include media(tl) {
    & > button {
      max-width: 234px;
    }
  }
}

.providerListButton {
  border: 1px solid $cloudy-gray;
  color: $jet;
  font-weight: normal;
  padding: 0 $spacing-base;
  width: 100%;
  white-space: nowrap;
  text-align: left;
  display: flex;
  align-items: center;
  min-width: 0;

  > div {
    min-width: 0;
  }

  svg > g > g {
    fill: $jet !important;
  }

  @include media(pc, tl) {
    height: 44px;
    max-width: initial;
  }

  @include media(pc) {
    width: 282px;
  }

  &:hover {
    color: $jet;
  }
}

.providerListButtonLabel {
  @include text-truncate;
}

.providerInfoButton {
  @include media(pc, tl) {
    height: 40px;
  }
}

.topAreaAmountWrapper {
  height: 48px;

  @include media(pc) {
    width: 272px;
  }
}

.topAreaAmountButton {
  &#{&} {
    height: 48px;
    border: 1px solid $cloudy-gray;
    @include media(tl, pc) {
      border-radius: $border-radius-8px;

      &:hover {
        border-color: $lincoln-green;
      }
    }
    @include media(sp) {
      border-radius: $border-radius-6px;
    }
  }
}

.vacancyCalendarAmountWrapper {
  padding: $spacing-16;
}
