@import '~@travel-ui/styles/src/utility';

.wrapper {
  @include font-sub-body;
  @include media(tl, pc) {
    font-size: $font-venus;
  }
}

.warningWrapper {
  margin-top: $spacing-base;
}

.errorWrapper {
  // override
  padding-top: 0;

  .icon {
    @include media(sp) {
      margin-top: 2px;
    }
  }
}

.infoIcon path:nth-child(2) {
  fill: $jet;
}
