@import '~@travel-ui/styles/src/utility';

.wrapper {
  display: flex;
  flex-shrink: 0;
  user-select: none;
}

.item {
  @include flex-center;
  white-space: nowrap;
}
