@import '~@travel-ui/styles/src/utility';

$selected-color: $cilantro;
$highlight-color: $laurel-green;

@mixin hovered {
  .item {
    background: $highlight-color;
  }
}
@mixin selected {
  .item {
    background: $selected-color;
    color: $white;
    pointer-events: none;
  }
}
@mixin half-background-color($side, $color: $highlight-color) {
  background: linear-gradient(to $side, transparent 50%, $color 50%);
}

.wrapper {
  background: $white;
  color: $selected-color;
  user-select: none;

  tr:not(:last-child) {
    border-bottom: 2px solid $white;
  }
}

.itemWrapper {
  // day item
  .item {
    @include flex-center;
    @include box(40px);
    border-radius: 50%;
    cursor: pointer;
    margin: auto;
  }

  &:not(.invalidMonth) {
    .today {
      border: 1px solid $pastel-gray;
    }

    // styling - selected state
    > .selected {
      @include selected;
    }
    > .selectedStartDate {
      @include selected;
      @include half-background-color(right);
    }
    > .selectedEndDate {
      @include selected;
      @include half-background-color(left);
    }
    > .hoveredStartDate {
      @include hovered;
      @include half-background-color(right);
    }
    > .hoveredEndDate {
      @include hovered;
      @include half-background-color(left);
    }
    > .inSelectedRange {
      background: $highlight-color;
    }
  }

  &.invalidMonth {
    > .selectedEndDate,
    > .hoveredStartDate,
    > .hoveredEndDate,
    > .inSelectedRange {
      background: $highlight-color;
    }
  }

  > .unSelected {
    color: inherit;
    &:hover > .item {
      background: $highlight-color;
    }
  }
  // styling - day state
  &.outDated {
    pointer-events: none;
    color: $pastel-gray;
  }
  &.invalidMonth {
    pointer-events: none;
    color: transparent;
  }
  &.weekend {
    color: $apple-red;
  }
  &.default {
    color: inherit;
  }
}
