@import '~@travel-ui/styles/src/utility';

.header {
  @include font-label;
  color: $jet;
  margin-bottom: $spacing-m;

  @include media(pc, tl) {
    font-size: $font-neptune;
  }
}
